import * as React from 'react'
import LayoutWork from '../../components/layoutWork'
import {Container} from "react-bootstrap" 
import head from '../../images/portfolio/scarcities/thumbnail-scarcities.png'
import markthal from '../../images/portfolio/scarcities/markthal-ai-vision.mp4'
import heatmap from '../../images/portfolio/scarcities/3D-heatmap-Appartement.mp4'
import { Link } from 'gatsby'

const PortfolioCityPage = () => {
  return (
    <LayoutWork pageTitle="Scarcities">
      <Container><p className='info'>Research | With | <Link to='https://armangungor.nl/'>Arman Güngör</Link> &#38; <Link to=''>Hendrik Do</Link></p></Container><hr/>
      <Container>
        <br/>
        <h1>Scarcities</h1>
        <h2>When we look around us, we are surrounded by data. By making this data usable, we have developed a module in which images are converted into heat maps. With this, the use of space is visualized and accurately depicted.</h2>
        <br/>
        <img src={head} className="fullimg" alt="3d view of apartment"/>
        <br/>
        <br/>
        <p>The project at hand aims to tackle the issue of urban densification and the increasing need for efficient utilization of space. In this regard, we have developed a module that converts images into heat maps, providing an accurate visualization of the use of space.</p>
        <br/>
        <p>The challenges faced by today's designers are numerous, with the high cost and scarcity of square meters being a significant obstacle. The densification process requires innovative solutions for small and large spatial interventions, and it is crucial to identify the most promising areas for investments and designs. This is where our project comes in, providing valuable insights into the utilization of space.</p>
        <br/>
        <video src={markthal} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
        <br/>
        <br/>
        <p>Raw data is often insufficient and requires transformation into useful information. With our module, we can create heat maps from poorly designed public or privately owned areas, revealing additional information that can lead to increased activity and natural surveillance.</p>
        <br/>
        <p>The result is a comprehensive spatial understanding, which can be achieved by utilizing 3D heatmap models. By converting raw data into usable knowledge, our project can provide designers and urban planners with valuable insights into the utilization of space and aid in the development of efficient and sustainable urban solutions.</p>
        <br/>
        <video src={heatmap} width="100%" height="auto" autoplay="true" loop><track kind="captions" srcLang="en" label="English" default /></video>
      </Container>
    </LayoutWork>
    
  )
}
export default PortfolioCityPage